function getApp(state, name) {
  let newApp = false;

  state.applications.forEach(function(app) {
    if (app.name === name) {
      if (state.active === null || state.active.name !== name) {
        newApp = app;
      }
    }
  }, this);

  return newApp;
}

export default {
  namespaced: true,
  state: {
    active: false,
    applications: []
  },
  mutations: {
    change(state, name) {
      let newApp = getApp(state, name);
      if (newApp) {
        state.active = newApp;
      }
    },
    activate(state, name) {
      //console.log(this);
      //console.log("Activando:", name);

      if (state.active !== null && state.active.name === name) {
        console.log("moduleApps: misma app, sale");
        return;
      }

      //let changeRoute = true;
      let newApp = getApp(state, name);

      if (newApp !== false) {
        /*if (!router.currentRoute.meta) {
        }*/

        //console.log("router.currentRoute::", router.currentRoute);
        /*if (router.currentRoute.meta && router.currentRoute.meta.app === name) {
          changeRoute = false;
        }*/

        state.active = newApp;

        /*if (changeRoute) {
          //console.log("Navegando hacia", newApp.route);
          router.push({ name: newApp.route });
        }*/
      }
    },
    add(state, app) {
      state.applications.push(app);
    }
  },
  actions: {
    activate({ commit }, data) {
      //console.log("apps.actions.activate", data);
      commit("activate", data);
    },
    change({ commit }, data) {
      commit("change", data);
    },
    home(context) {
      var found = context.state.applications.find(app => {
        return (app.route === "home");
      });
      if (found) {
        context.commit("activate", found.name);
      }
    },
    addApplication(context, app) {
      //console.log("Agregando app", app.name);
      app.routes.forEach(function(route) {
        if (typeof route.meta !== "undefined") {
          route.meta.app = app.name;
        } else {
          route.meta = {
            app: app.name
          };
        }
      });

      context.commit("add", app);
    }
  }
};
