import { base } from "@linkedcode/axios-plugin";

export default {
  namespaced: true,
  state: {
    all: {},
    active: {},
    me: false
  },
  getters: {
    me: state => state.me ? state.me : false,
    username: state => state.me ? state.me.name : false,
  },
  mutations: {
    setProfile(state, data) {
      state.me = data;
    }
  },
  actions: {
    load(context, data) {
      return new Promise(function(resolve, reject) {
        base.get(`profile/edit`).then(function(response) {
          resolve(response.data);
        });
      })
    },
    logout({ commit }, data) {
      commit('setProfile', false);
    },
    me({ commit, dispatch }, data) {
      return new Promise(function(resolve, reject) {
        base.get(`profile/me`).then(function(response) {
          resolve(response.data);
          commit('setProfile', response.data);
        }).catch(function(err){
          console.log(err.message);
          dispatch('notifications/error', { msg: err.message }, { root: true });
        });
      })
    },
    update(context, data) {
      return new Promise(function(resolve, reject) {
        base.put(`profile/update`, data.item).then(function(response) {
          context.dispatch('me')
          context.dispatch('notifications/success', { msg: 'Perfil actualizado '}, { root: true })
          resolve(response.data);
        });
      })
    }
  }
};
