//import { createApp } from "vue";
import appStore from "./store";
import AppsMenu from "./AppsMenu.vue";
import AppsMenuBs from "./AppsMenuBs.vue";

/*function createAppStore({ router, store, apps }) {
  return createApp({
    router,
    store,
    data() {
      return {
        apps: apps,
        active: null
      };
    },
    methods: {
      getApps() {
        return this.apps;
      },
      setActive(app) {
        this.active = app;
      },
      getActive() {
        return this.active;
      },
    }
  });
}*/

export default {
  installed: false,
  install(app, options) {
    if (this.installed) {
      return;
    }

    if (typeof options == 'undefined') {
      console.warn("options is required")
    }

    if (typeof options.store == 'undefined') {
      console.warn("options.store is required")
    }

    if (typeof options.router == 'undefined') {
      console.warn("options.router is required")
    }

    options.store.registerModule("apps", appStore);

    app.config.globalProperties.$appStore = {
      add: function(application, activate) {
        application.routes.forEach(element => {
            if (element.meta) {
              element.meta.app = app.name;
            } else {
              element.meta = {
                app: app.name
              };
            }
  
            //console.log('Agregando ruta', element);
            options.router.addRoute(element);
            //app.config.globalProperties.$router.addRoute(element);
          });
          
          if (application.vuex) {
            if (Array.isArray(application.vuex)) {
              application.vuex.forEach(function(vuex) {
                options.store.registerModule(vuex.name, vuex.module);
              }, this);
            } else {
              options.store.registerModule(
                application.vuex.name, 
                application.vuex.module
              );
            }
          }

          options.store.dispatch("apps/addApplication", application);
          
          if (activate) {
            this.activate(application);
          }
      },
      activate: function(application) {
        //console.log("activate");
        let activate = false;

        if (typeof app.config.globalProperties.$route.name !== "undefined") {
          application.routes.forEach(function(route) {
            if (route.name === app.config.globalProperties.$route.name) {
              activate = true;
            }
          });
        } else {
          activate = true;
        }

        if (activate === true) {
          options.store.dispatch("apps/activate", application.name);
        }
      }
    };

    app.component('apps-menu', AppsMenu);
    app.component('apps-menu-bs', AppsMenuBs);

    this.installed = true;
  }
};